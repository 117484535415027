.guideContainer{
  color: white;
  background-color: black;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  font-size: 2em;
  margin-top: 2em;
}
.listItem{
  margin-top:1em;
}