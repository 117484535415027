.reusablePost{
  width: 100%;
  height: 100vh;
}
.text{
  height: 100vh;
  text-align: center;
  font-size: 1.5em;
  font-weight: bolder;
  background-color: black;
}
.innerText{
    background-color: white;
    width: 70%;
    margin: auto;
    color: black;
    border: 1 0 1 0 solid black;
    font-size: 2.5em;
    border-left: 4px solid green;
    border-right: 4px solid green;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y:scroll;
}
.reusableImg{
  width: 7em;
  align-self: center;
  height: 5em;
}