.adminNews{
  display: flex;
  flex-direction: column;
}
.adminDivStyle{
  font-size: 2em;
  font-weight: bold;
  margin-top: 2em;
  display: flex;
  justify-content: center;
}
.doneBtn{
  width: 20em;
  height: 3.5em;
  align-self: center;
  margin-top: 4em;
  background-color: black;
  color: white;
  font-weight: bolder;
}