.news{
  width: 100%;
}
.newsContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: black;
  padding-bottom: 5em;
  margin-top:3em;
}
.post{
  margin-top: 4em;
  width: 40%;
  color:white;
  border:4px solid #47A22D;
  outline: 4px solid white;
}
.newsTitle{
  text-align: center;
  color: white;
  margin-bottom: 0;
  background-color: black;
}
#img0{
  border:4px solid #E3010F;
  align-self: center;
  width:100%;
  height:25em;
}
#img1{
  border:4px solid #E3010F;
  align-self: center;
  width:100%;
  height:25em;
}
#img2{
  border:4px solid #E3010F;
  align-self: center;
  width:100%;
  height:25em;
}
#img3{
  border:4px solid #E3010F;
  align-self: center;
  width:100%;
  height:25em;
}