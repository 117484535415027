.App {
  text-align: center;
}
.mainLogo{
  width: 50vw;
  margin-top: 3em;
}
.header{
  display: flex;
  justify-content: space-evenly;
}
.menuBtn{
  background-color: white;
  border: none;
  height: 5em;
}
.loginBtn{
  margin-left: auto;
  height: 4em;
  width: 7em;
  margin-top: 0.8em;
  margin-right: 0.8em;
  background-color: white;
  font-weight: bolder;
}
.navbar{
  margin-top: 2em;
  background-color: black;
  font-size: 2em;
  margin-bottom: 0;
}
.nav-link {
  color: white !important;
}

.navbar-nav{
  width: 100%;
  justify-content: space-evenly;
  font-weight: bolder;
}

.members{
  margin-top:2em;
  background: #000;
  color: #fff;
  padding: 3em;
}
.members h2{
  font-weight: bolder;
}
.member-cards{
  max-width: 1280px;
  line-height: 1.7;
  padding: 2em;
  margin: 0 auto;
  list-style: none;
  padding: 0;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.memberImg{
  display: block;
  text-align: center;
}
.member-cards li{
  flex: 0 0 28%;
}
.imgCircle{
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin-top: 3em;
}
.memberName{
  margin-top: 2em;
  font-weight: bolder;
}
.bio{
  text-align: left;
  font-weight: bold;
}
.footer{
  margin-top: 4em;
  padding-bottom: 4em;
  background-color: black;
  color: white;
}
.join{
  font-weight: bolder;
  padding-top: 2em;
}
.subscribe{
  border: none;
  width: 35em;
  height: 2.5em;
  display: block;
  margin: auto;
}
.subscribeBtn{
  margin-top: 1em;
  height: 3em;
  width: 10em;
  background-color: white;
  font-weight: bolder;

}
.links{
  display: flex;
  justify-content: space-evenly;
  height:15em;
  align-items: center;
}
.links a{
  color:black;
  font-size: 1.5em;
}
.modal-header{
  background-color: white;
  color:black;
  font-weight: bolder;
}
.modal-header div{
  margin: auto !important;
  margin-left: 5em !important;
}
.modalLink{
  background-color: transparent;
  border:none;
  font-weight: bolder;
  align-self: center;
  color:white;
  border-left: 1px solid red;
}
.modal-body{
  background-color: black;
  display: flex;
  flex-flow: column;
}