.loginContainer{
  width: 100%;
  height: 28em;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inputField{
  width: 40%;
  height: 3em;
  margin-top: 1em;
}
#login{
  height: 4em;
  width: 7em;
  margin-top: 0.8em;
  margin-right: 0.8em;
  background-color: white;
  font-weight: bolder;
}