.standpointContainer{
  width: 100%;
  height: 100vh;
}
.subCatContainer{
  display: flex;
  margin-top:3em;
  justify-content: space-evenly;
  background-color: black;
  padding:1em;
}
.subContainer{
  background-color: black;
  border:2px solid white;
  border-radius: 10%;
}
.subContainer img{
  padding-top: 1px;
  width:300px;
  height: 300px;
  border-radius: 50%;
}
.link{
  color: white;
  font-weight: bolder;
  text-align: center;
  font-size: 2em;
  border: 1px solid black;
  text-decoration: none;
}
.link p{
  margin-top: none;
}
.link:hover{
  text-decoration: none;
  pointer-events:none;
  color: #47A22D;
}
