.formContainer{
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.signUp{
  display: flex; 
    width: 20em;
    margin: auto;
    border: 2px solid #E2010F;
    height: 3.5em;
    background-color: white;
    font-weight: bold;
    margin-bottom: 3em;
    margin-top: 3em;
    display: table-cell;
    vertical-align: middle;
}
.form{
  display: flex;
  font-size: 1.5em;
  font-weight: bolder;
  justify-content: center;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.form label{
  width: 10em;
  color:#47A22D;
  text-align: start; 
}
.inputContainer{
  border: 4px solid white;
  border-radius: 1%;
  margin-left: 25%;
  margin-right: 25%;
}