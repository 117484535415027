.contactContainer{
  width: 100%;
  height: 100vh;
}

.ulStyle{
  display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.contactInfoContainer{
  color: white;
    background-color: black;
    margin-top: 3em;
    font-size: 2em;
    height: 50%;
    display: flex;
    width: auto;
}