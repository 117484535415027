.adminHeader {
  text-align: center;
}
.mainLogo{
  width: 50vw;
  margin-top: 3em;
}
.header{
  display: flex;
  justify-content: space-evenly;
}
.menuBtn{
  background-color: white;
  border: none;
  height: 5em;
}
.logoutBtn{
  height: 4em;
  width: 7em;
  margin-top: 0.8em;
  margin-right: 0.8em;
  background-color: white;
  font-weight: bolder;
}
.adminNavbar{
  margin-top: 2em;
  background-color: black;
  font-size: 2em;
  margin-bottom: 0;
}
.nav-link {
  color: white !important;
}

.navbar-nav{
  width: 100%;
  justify-content: space-evenly;
  font-weight: bolder;
}
.adminControl{
  background-color: black;
    width: 36rem;
    color: white;
    font-weight: bold;
    margin: auto;
    height: 2em;
    border-radius: 5px 5px 50px 50px;
    font-size: 1.7em;
}
